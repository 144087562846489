import React, {useEffect, useState} from "react";
import {useRecoilValueLoadable} from "recoil";
import {interests} from "../../Recoil/selectors";

export default function Interests({hobbies, setHobbies}){

    const interestsValue = useRecoilValueLoadable(interests);

    const switchHobbie = (e) => {
        let c = e.target.checked;
        let hobArray = [...hobbies];
        if(c){
            hobArray = [...hobArray, e.target.value];
            setHobbies(hobArray);
        }
        else{
            hobArray = hobbies.filter(h => h !== e.target.value);
            setHobbies(hobArray);
        }
    }

    useEffect(()=>{
        console.log(hobbies);
    }, [hobbies])

    switch (interestsValue.state) {
        case 'hasValue':
            return (
                <div className="grid3 col-gap-2 row-gap mobile:grid2">
                    {interestsValue.contents.map(({title, id})=>{
                        return(
                            <div className="flex align-center f-medium f-600 mobile:f-1rem" key={id}>
                                <input type="checkbox" value={id} className="mr-half shrinks-0" onChange={switchHobbie} checked={hobbies.includes(id)} />{title}
                            </div>
                        )
                    })}

                </div>
            )
        case 'loading':
            return <div>Loading...</div>
        case 'hasError':
            return (
                <div>Error</div>
            )
    }

}
