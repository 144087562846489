import React, {useEffect, useState} from "react";
import Button from "../../Components/UI/Button";
import Genders from "../Form/Genders";
import BirthDate from "../Form/BirthDate";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import UploadPicture from "../Form/UploadPicture";
import StepHeader from "./StepHeader";
import Error from "../UI/Error";


export default function Step1({save}){

    let history = useHistory();

    const user = useRecoilValue(userAtom);
    let u = {...user};

    const [error, setError] = useState("");

    const [profile, setProfile] = useState({
        birthday : null,
        birthMonth : -1,
        gender : u.gender?.id ? u.gender.id : u.gender,
    });

    const onSelect = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setProfile({...profile, [name] : val});
    }

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setProfile({...profile, [name] : val});
    }

    useEffect(()=> {
        console.log(profile);
        console.log(user);
    },[profile]);



    const saveProfile = async () => {

        if(!user.profilePhoto){
            setError("Please upload a profile photo to continue")
            return
        }

        try {
          const res = await save(profile);
          history.push("/profile/2")
        }
        catch (e){
           console.log(e);
        }

    }

    const formatBirthDate = (bd) => {
        if (bd && bd.length === 10){
            let da = bd.split('/');
            const birthDate=new Date(+da[2], da[0] - 1, +da[1]);
            setProfile({...profile, birthday : birthDate.toISOString(), birthMonth : da[0] - 1});
        }
    }

    return(
        <div>
            <StepHeader step={1} />
            <div className="max-w-600px mhor-auto mobile:phor">

                <div className="pb-2 flex justify-center mobile:pb">
                    <UploadPicture save={save} />
                </div>

                <div className="mb-2 grid2 col-gap-2 mobile:block mobile:mb">
                    <BirthDate format={formatBirthDate} className="mobile:mb" />
                    <Genders onSelect={onSelect} className="mobile:mb" value={profile.gender} />
                </div>

                {error &&
                <Error msg={error} className="t-red block center mb" />
                }

                <div className="flex justify-center">
                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveProfile}>
                        Next
                    </Button>
                </div>

            </div>
        </div>
    )
}
