import axios from "axios";
import {url} from "../atoms";

export const getGenders = async () => {

    try {
        const res = await axios.get(`${url}genders`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}


export const getHobbies = async () => {

    try {
        const res = await axios.get(`${url}hobbies`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getSpirits = async () => {

    try {
        const res = await axios.get(`${url}drinks`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}


export const getMaritalStatuses = async () => {

    try {
        const res = await axios.get(`${url}marital-statuses`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getDrinks = async () => {

    try {
        const res = await axios.get(`${url}drinks`, {withCredentials : true});
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}


export const getCovers = async () => {

    try {
        const res = await axios.get(
            `${url}covers`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getReferrals = async (email) => {

    let e = encodeURIComponent(email);

    try {
        const res = await axios.get(
            `${url}referals?email=${e}`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            }
        );
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const getReferalsPhone = async (phone) => {

    try {
        const res = await axios.get(
            `${url}referals?phone=${phone}&_sort=createdAt:DESC`,
            {
                withCredentials : true
            });
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }
}

export const getInvitation = async (id) => {
    try {
        const res = await axios.get(
            `${url}invitations/${id}`,
            {
                withCredentials : true
            });
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }
}

export const updateReferal = async (id, phone) => {

    const data = {completed : true, phone : phone};

    try {
        const res = await axios.put(
            `${url}referals/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}


export const updateInvitation = async (id) => {

    const data = {completed : true};

    try {
        const res = await axios.put(
            `${url}invitations/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}


export const createInvitation = async (data) => {

    try {
        const res = await axios.post(
            `${url}invitations`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}


export const getNextCode = async () => {

    try {
        const res = await axios.get(
            `${url}usercodes?_sort=code:DESC&_limit=1`,
            {
                withCredentials : true
            }
        );
        return (res.data);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const saveCode = async (data) => {

    try {
        const res = await axios.post(
            `${url}usercodes`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}


export const uploadCloudinary = async (data) => {

    try {
        const res = await axios.post(
            `https://api.Cloudinary.com/v1_1/thepark/image/upload`,
            data
            );
        return (res);
    }
    catch (e){
        throw e
    }


}



