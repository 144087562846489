import React from "react";

export default function IconEye({className, color}){
    return(
        <svg width="53" height="38" viewBox="0 0 53 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M27.0839 0C32.5355 0.0527847 37.855 1.69859 42.3984 4.73816C46.9418 7.77772 50.5142 12.0807 52.6831 17.1261C52.8901 17.5645 52.9975 18.0441 52.9975 18.5297C52.9975 19.0153 52.8901 19.4948 52.6831 19.9333C50.5275 24.9932 46.9504 29.3059 42.3933 32.3392C37.8362 35.3724 32.4985 36.9936 27.0387 37.0026C21.5788 37.0116 16.2358 35.4081 11.6689 32.3899C7.10198 29.3717 3.51096 25.0709 1.33892 20.0181C1.11369 19.5634 0.996445 19.062 0.996445 18.5537C0.996445 18.0454 1.11369 17.544 1.33892 17.0893C5.75587 7.50196 13.1361 1.91672 23.4797 0.333588C24.6736 0.155486 25.8844 0.110254 27.0839 0ZM26.9353 34.5066C37.2658 33.9581 45.0319 29.3218 49.858 19.9333C50.3149 19.04 50.4186 18.2625 49.9421 17.3127C45.1581 8.01742 37.6106 2.82984 27.2408 2.74786C16.7618 2.66588 9.04896 7.76582 4.15276 17.1487C3.90378 17.5621 3.77025 18.0358 3.7663 18.5195C3.76235 19.0032 3.88814 19.479 4.13035 19.8965C8.91724 29.2172 16.6077 33.8988 26.9353 34.5066V34.5066Z" className={color ? color : "black"} />
            <path d="M26.9832 29.0872C24.9155 29.0844 22.8951 28.4631 21.1774 27.302C19.4598 26.1409 18.1221 24.492 17.3335 22.564C16.5449 20.6359 16.3409 18.5153 16.7472 16.4703C17.1535 14.4253 18.1519 12.5478 19.6162 11.0751C21.0804 9.60251 22.9447 8.60096 24.9732 8.19715C27.0018 7.79334 29.1035 8.0054 31.0126 8.80653C32.9217 9.60765 34.5523 10.9618 35.6984 12.6978C36.8444 14.4338 37.4543 16.4737 37.451 18.5594C37.4384 21.353 36.3306 24.0281 34.3693 26.0006C32.408 27.9731 29.7527 29.0827 26.9832 29.0872V29.0872ZM34.9006 18.5141C34.8754 14.1944 31.1871 10.5165 26.9355 10.573C24.8689 10.6198 22.8997 11.4688 21.438 12.9432C19.9762 14.4177 19.1346 16.404 19.0882 18.4887C19.0489 22.7744 22.7148 26.4948 26.9804 26.5033C31.246 26.5117 34.9258 22.8055 34.9006 18.5141Z"  className={color ? color : "black"} />
        </svg>

    )
}
