import React, {useEffect} from "react";
import {useRecoilValueLoadable} from "recoil";
import {interests, spirits} from "../../Recoil/selectors";

export default function Spirits({spiritsA, setSpirits}){

    const spiritsValue = useRecoilValueLoadable(spirits);

    const switchSpirit = (e) => {
        let c = e.target.checked;
        let spiArray = [...spiritsA];
        if(c){
            spiArray = [...spiArray, e.target.value];
            setSpirits(spiArray);
        }
        else{
            spiArray = spiritsA.filter(h => h !== e.target.value);
            setSpirits(spiArray);
        }
    }

    useEffect(()=>{
        console.log(spiritsA);
    }, [spiritsA])

    switch (spiritsValue.state) {
        case 'hasValue':
            return (
                <div className="grid3 col-gap-2 row-gap mobile:grid2">
                    {spiritsValue.contents.map(({title, id})=>{
                        return(
                            <div className="flex align-center f-medium f-600 mobile:f-1rem" key={id}>
                                <input type="checkbox" value={id} className="mr-half shrinks-0" onChange={switchSpirit} checked={spiritsA.includes(id)} />{title}
                            </div>
                        )
                    })}

                </div>
            )
        case 'loading':
            return <div>Loading...</div>
        case 'hasError':
            return (
                <div>Error</div>
            )
    }

}
