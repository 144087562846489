import React, {useState} from "react";
import {useHistory} from "react-router-dom";

import Input from "../Components/UI/Input";
import Button from "../Components/UI/Button";
import Title from "../Components/UI/Title";

import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {forgotPassword, login} from "../Recoil/api/user";
import Error from "../Components/UI/Error";


export default function ForgotPassword (){

    const [user, setUser] = useRecoilState(userAtom);

    let history = useHistory();

    const [identifier, setIdentifier] = useState("");
    const [pwRequested, setPwRequested] = useState(false)
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let val = e.target.value;
        setIdentifier(val);
    }

    const submit = async (e) => {
        setError("");
        e.preventDefault();

        try {
            const su = await forgotPassword({email : identifier});
            console.log(su)
            setPwRequested(true);
        }
        catch (e){
            console.log("SETTING ERROR---", e.response);
        }

    }


    return(
        <div>

            {user.id && history.push('/profile/1')}

            <div className="max-w-500px mhor-auto center pver-3">

                <Title title="Forgot Password" />

                <p className="f-500 mb-half f-playfair mobile:phor">
                    Request your password reset below
                </p>
                <p className="f-1rem mobile:phor">
                    You will receive instructions via email
                </p>
            </div>

            {pwRequested ?

                <p className="f-1rem mobile:phor center max-w-600px mhor-auto">
                    New password request was sent. Please check your email
                </p>

            :
                <div className="max-w-600px mhor-auto mobile:phor">

                    <Input label="Email" className="mb-2" name="identifier" value={identifier} onChange={updateUser} />

                    <div className="flex justify-center">
                        <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={submit}>
                            Reset Password
                        </Button>
                    </div>

                    {error && <Error msg={error} className="t-red center mt" /> }

                </div>
            }





        </div>
    )
}
