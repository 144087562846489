import axios from "axios";
import {url} from "../atoms";

export const login = async (data) => {

    try {
        const res = await axios.post(`${url}auth/local`, data, {withCredentials : true});
        console.log(res);
        return (res);
    }
    catch (e){
        console.log(e);
        throw e
    }

}

export const signup = async (data) => {

    let d = {...data};
    d.username = data.email;
    console.log("DD", d);

    try {
        const res = await axios.post(`${url}auth/local/register`, d, {withCredentials : true});
        return (res);
    }
    catch (e){
        throw e
    }

}

export const getUser = async () => {

    try {
        const res = await axios.get(
            `${url}users/me`,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}

export const updateUser = async (id, data) => {
    try {
        const res = await axios.put(
            `${url}users/${id}`,
            data,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}

export const createUserStatus = async (data) => {

    let d = {...data};

    try {
        const res = await axios.post(
            `${url}user-statuses`,
            d,
            {
                withCredentials : true,
                headers : {
                    Authorization : `Bearer ${window.localStorage.getItem('jwt')}`
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }

}

export const uploadPhoto = async (data) => {
    try {
        const res = await axios.post(
            `${url}upload`,
            data,
            {
                withCredentials : true,
                headers : {
                    'Authorization' : `Bearer ${window.localStorage.getItem('jwt')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
        return (res);
    }
    catch (e){
        throw e
    }
}


export const forgotPassword = async (data) => {

    try {
        const res = await axios.post(
            `${url}auth/forgot-password`,
            data,
            {
                withCredentials : true
            });
        return (res);
    }
    catch (e){
        throw e
    }
}

export const resetPassword = async (data) => {

    try {
        const res = await axios.post(
            `${url}auth/reset-password`,
            data,
            {
                withCredentials : true
            });
        return (res);
    }
    catch (e){
        throw e
    }
}
