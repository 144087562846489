const colors=[
	{
		name:'transparent',
		hex:'transparent'
	},
	{
		name:'gold',
		hex:'#b5975b'
	},
	{
		name:'black',
		hex:'#000000'
	},
	{
		name:'grey1000',
		hex:'#202020'
	},
	{
		name:'grey900',
		hex:'#323232'
	},

	{
		name:'grey800',
		hex:'#444444'
	},
	{
		name:'grey700',
		hex:'#888888'
	},
	{
		name:'grey600',
		hex:'#aaaaaa'
	},
	{
		name:'grey500',
		hex:'#cccccc'
	},
	{
		name:'grey400',
		hex:'#DADCE0'
	},
	{
		name:'grey300',
		hex:'#e5e5e5'
	},
	{
		name:'grey200',
		hex:'#f1f1f1'
	},
	{
		name:'grey100',
		hex:'#f9f9f9'
	},
	{
		name:'white',
		hex:'#FFFFFF'
	},
	{
		name:'white90',
		hex:'rgba(255,255,255,0.9)'
	},
	{
		name:'white80',
		hex:'rgba(255,255,255,0.8)'
	},
	{
		name:'white50',
		hex:'rgba(255,255,255,0.5)'
	},
	{
		name:'white40',
		hex:'rgba(255,255,255,0.4)'
	},
	{
		name:'white30',
		hex:'rgba(255,255,255,0.3)'
	},
	{
		name:'white20',
		hex:'rgba(255,255,255,0.2)'
	},
	{
		name:'white10',
		hex:'rgba(255,255,255,0.1)'
	},
	{
		name:'black90',
		hex:'rgba(0,0,0,0.9)'
	},
	{
		name:'black80',
		hex:'rgba(0,0,0,0.8)'
	},
	{
		name:'black70',
		hex:'rgba(0,0,0,0.7)'
	},
	{
		name:'black50',
		hex:'rgba(0,0,0,0.5)'
	},
	{
		name:'black40',
		hex:'rgba(0,0,0,0.4)'
	},
	{
		name:'black30',
		hex:'rgba(0,0,0,0.3)'
	},
	{
		name:'black20',
		hex:'rgba(0,0,0,0.2)'
	},
	{
		name:'black10',
		hex:'rgba(0,0,0,0.1)'
	},
	{
		name:'black05',
		hex:'rgba(0,0,0,0.05)'
	},
	{
		name:'red',
		hex:'#F18080'
	},
	{
		name:'green',
		hex:'#80F18B'
	}
];


let style=colors.map(
	({name,hex})=>{
		return (
			`.t-${name}, .hover\\:t-${name}:hover{ color: ${hex}; }
.${name}, .hover\\:${name}:hover{ background-color: ${hex}; fill: ${hex} }
.b-${name}, .hover\\:b-${name}:hover, .focus\\:b-${name}:focus{ border-color: ${hex}; stroke: ${hex}; }
.sh-4-${name}, .hover\\:sh-4-${name}:hover, .focus\\:sh-4-${name}:focus{box-shadow: 0px 0px 4px ${hex};}
.sh-10-${name}, .hover\\:sh-10-${name}:hover, .focus\\:sh-10-${name}:focus{filter: drop-shadow(0px 0px 10px ${hex});}
.sh-20-${name}, .hover\\:sh-20-${name}:hover, .focus\\:sh-20-${name}:focus{filter: drop-shadow(0px 0px 20px ${hex});}
@media (max-width: 640px) {
.mobile\\:${name} { background-color: ${hex}}
.mobile\\:b-${name} { border-color: ${hex}; stroke: ${hex}; }
}`
		);
	}
).join(' \n\n');


function addcss(css){
	var head=document.getElementsByTagName('head')[0];
	var s=document.createElement('style');
	s.setAttribute('type','text/css');
	if(s.styleSheet){   // IE
		s.styleSheet.cssText=css;
	}else{                // the world
		s.appendChild(document.createTextNode(css));
	}
	head.appendChild(s);
}

addcss(style);
