import React from "react";
import {Route, Switch, useHistory, useParams} from "react-router-dom";

import Step1 from "../Components/Profile/Step1";
import Step2 from "../Components/Profile/Step2";
import Step3 from "../Components/Profile/Step3";
import Step4 from "../Components/Profile/Step4";
import Step5 from "../Components/Profile/Step5";
import Step6 from "../Components/Profile/Step6";
import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {updateUser} from "../Recoil/api/user";
import cioIdentify from "../CIO/cio";

export default function Profile (){

    let history = useHistory();
    let {step} = useParams()

    const [user, setUser] = useRecoilState(userAtom);

    if(user &&  user.profileCompleted && step !== "refer"){
        history.push("/");
    }

    const save = async (data) => {
        try{
            const res = await updateUser(user.id, data);
            setUser(res.data);
            cioIdentify(res.data);
            return res
        }
        catch (e){
            throw e
        }
    }

    return(
        <div>

            <div className="">
                <Switch>
                    <Route exact path="/profile/1">
                        <Step1 save={save} />
                    </Route>
                    <Route exact path="/profile/2">
                        <Step2 save={save} />
                    </Route>
                    <Route exact path="/profile/3">
                        <Step3 save={save} />
                    </Route>
                    <Route exact path="/profile/4">
                        <Step4 save={save} />
                    </Route>
                    <Route exact path="/profile/5">
                        <Step5 save={save} />
                    </Route>
                    <Route exact path="/profile/6">
                        <Step6 save={save} />
                    </Route>
                    <Route exact path="/profile/refer">
                        <Step6 save={save} noStep />
                    </Route>
                </Switch>
            </div>

        </div>
    )
}
