import React, {useState} from 'react'

export default function Select({label, options, className, width, ...props}){

    const [defaultClass] = useState(`f-400 h-3rem phor b b-grey500 br-half f-1rem ${width ? width : "w-100"}`)

    console.log(options);

    return(
        <div className={className}>
            {label &&
                <label className="f-small mb-half block f-700">{label}</label>
            }
            <div className="relative">
                <select className={defaultClass} placeholder={label} {...props}>
                    <option value="">
                        Select one...
                    </option>
                    {options?.map( ({title, id}) => {
                        return(
                            <option key={title} value={id ? id : title}>
                                {title}
                            </option>
                        )
                    })}
                </select>
            </div>

        </div>
    )

}
