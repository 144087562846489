import React from "react";

import logoWhite from './UI/Styles/img/logo-white.png'
import logo from './UI/Styles/img/logo.png'
import Button from "./UI/Button";
import {useRecoilValue} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {Link} from "react-router-dom";

export default function Header({light}){

    const user = useRecoilValue(userAtom);


    const logout = () => {
        window.localStorage.clear();
        window.location="/"
    }

    return(
        <header className="flex justify-center relative z-1000 mobile phor">
            <div className={`max-w-1056px mobile:pver pver-2 ${light ? 'mt' : 'b-bottom b-grey400'} grows flex justify-center relative mobile:justify-start`}>
                <Link to="/">
                    <img src={light ? logoWhite : logo} alt="The Park Logo" className="h-4rem w-auto" />
                </Link>


                <div className="absolute right-0 h-4rem flex justify-end align-center">
                    {user.id ?
                        <>
                            <Button className="phor-2 mobile:phor hover:white hover:t-gold transition-5" height="h-2rem" onClick={logout}>
                                Logout
                            </Button>
                        </>
                        :
                        <>
                            {/*<Button type="Link" to="/signup" className="phor-2 mobile:phor hover:white hover:t-gold transition-5" height="h-2rem">
                                Join Now
                            </Button>*/}
                            <Button type="Link" to="/login" className="phor-2 mobile:phor hover:white hover:t-black transition-5 b-black ml mobile:ml-half" height="h-2rem" color="black" fontColor="t-white">
                                Login
                            </Button>
                        </>
                    }

                </div>

            </div>

        </header>
    )
}
