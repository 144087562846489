import React, {useEffect, useState} from "react";
import Button from "../UI/Button";
import {getUser, uploadPhoto} from "../../Recoil/api/user";
import {useRecoilState, useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {uploadCloudinary} from "../../Recoil/api/data";
import Error from "../UI/Error";

export default function UploadPicture({save}){

    const [user, setUser] = useRecoilState(userAtom);

    const [photo, setPhoto] = useState(user.profilePhoto?.url ? user.profilePhoto.url : "" );
    const [uploading, setUploading] = useState(false);

    const savePicture = async (e) =>{
        setUploading(true);
        const file = e.target.files[0];
        let formData = new FormData();
        formData.append('files', file);
        formData.append('ref', 'user');
        formData.append('refId', user.id);
        formData.append('field', 'profilePhoto');
        formData.append('source', 'users-permissions');

        try {
            const res = await uploadPhoto(formData);
            setUser({...user, profilePhoto : res.data[0].related[0].profilePhoto});
            setPhoto(res.data[0].url);
            setUploading(false);
        }
        catch (e){
            console.log(e);
        }
    }

    return(
        <div className="mb-2 center">
            <div className="h-200px w-200px grey200 br mb mhor-auto overflow-hidden flex">
                {uploading ?
                    <p className="phor grows flex align-center justify-center">
                        Uploading Photo...
                    </p>
                    :
                    <>
                        {photo ?
                            <img src={photo} className="obj-cover grows"  />
                            :
                            <p className="phor grows flex align-center justify-center">
                                Please upload a photo
                            </p>

                        }
                    </>
                }
            </div>


            <Button fontSize="f-small" className="relative phor-2 hover:white hover:t-gold transition-5" height="h-2rem">
                Upload Photo
                <input type="file" onChange={savePicture} className="absolute edges w-100 h-100 op-0" />
            </Button>

        </div>
    )
}
