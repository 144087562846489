/* global _cio */

export default function cioIdentify(data){

    let d = {...data};
    d.id = d.email;

    if(d.phoneNumber){
        d.phoneNumber = `+1${d.phoneNumber}`
    }
    if(d.role){
        delete d.role
    }
    if(d.provider){
        delete d.provider
    }
    if(d.gender){
        d.gender = data.gender.label
    }
    if(d.profilePhoto){
        delete d.profilePhoto
    }
    if(d.userPhotoObject){
        d.userPhotoObject = null
    }

    if(d.createdAt){
        delete d.createdAt
    }
    if(d.updatedAt){
        delete d.updatedAt
    }
    if(d.hobbies){
        let hob = "";
        d.hobbies.forEach(h => {
            hob = `${hob}${hob ? ", " : ""} ${h.Title}`
        })
        d.hobbies = hob
    }

    if(d.favoriteSpirits){
        let fav = "";
        d.favoriteSpirits.forEach(h => {
            fav = `${fav}${fav ? ", " : ""}${h.title}`
        })
        d.favoriteSpirits = fav
    }

    if(d.birthday){
        let bd = new Date(d.birthday);
        console.log("BD", bd);
    }


    let ca = new Date()
    d.created_at = Math.floor(ca.getTime() / 1000);

    console.log("Identifying", d);
    _cio.identify(d);

}
