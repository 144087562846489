import {atom} from "recoil";
export const url = "https://thepark-api.herokuapp.com/";

export const cloudinaryUrl = atom({
        key: "cloudinary",
        default : "https://res.cloudinary.com/thepark/image/upload/v1637619784/"
    }

)

export const errorAtom = atom({
    key: 'Error',
    default: ''
});


export const userAtom = atom({
    key: 'User',
    default: {}
});


export const statesAtom = atom({
    key : "States",
    default : [
        {
            "title": "Alabama",
            "abbreviation": "AL"
        },
        {
            "title": "Alaska",
            "abbreviation": "AK"
        },
        {
            "title": "American Samoa",
            "abbreviation": "AS"
        },
        {
            "title": "Arizona",
            "abbreviation": "AZ"
        },
        {
            "title": "Arkansas",
            "abbreviation": "AR"
        },
        {
            "title": "California",
            "abbreviation": "CA"
        },
        {
            "title": "Colorado",
            "abbreviation": "CO"
        },
        {
            "title": "Connecticut",
            "abbreviation": "CT"
        },
        {
            "title": "Delaware",
            "abbreviation": "DE"
        },
        {
            "title": "District Of Columbia",
            "abbreviation": "DC"
        },
        {
            "title": "Federated States Of Micronesia",
            "abbreviation": "FM"
        },
        {
            "title": "Florida",
            "abbreviation": "FL"
        },
        {
            "title": "Georgia",
            "abbreviation": "GA"
        },
        {
            "title": "Guam",
            "abbreviation": "GU"
        },
        {
            "title": "Hawaii",
            "abbreviation": "HI"
        },
        {
            "title": "Idaho",
            "abbreviation": "ID"
        },
        {
            "title": "Illinois",
            "abbreviation": "IL"
        },
        {
            "title": "Indiana",
            "abbreviation": "IN"
        },
        {
            "title": "Iowa",
            "abbreviation": "IA"
        },
        {
            "title": "Kansas",
            "abbreviation": "KS"
        },
        {
            "title": "Kentucky",
            "abbreviation": "KY"
        },
        {
            "title": "Louisiana",
            "abbreviation": "LA"
        },
        {
            "title": "Maine",
            "abbreviation": "ME"
        },
        {
            "title": "Marshall Islands",
            "abbreviation": "MH"
        },
        {
            "title": "Maryland",
            "abbreviation": "MD"
        },
        {
            "title": "Massachusetts",
            "abbreviation": "MA"
        },
        {
            "title": "Michigan",
            "abbreviation": "MI"
        },
        {
            "title": "Minnesota",
            "abbreviation": "MN"
        },
        {
            "title": "Mississippi",
            "abbreviation": "MS"
        },
        {
            "title": "Missouri",
            "abbreviation": "MO"
        },
        {
            "title": "Montana",
            "abbreviation": "MT"
        },
        {
            "title": "Nebraska",
            "abbreviation": "NE"
        },
        {
            "title": "Nevada",
            "abbreviation": "NV"
        },
        {
            "title": "New Hampshire",
            "abbreviation": "NH"
        },
        {
            "title": "New Jersey",
            "abbreviation": "NJ"
        },
        {
            "title": "New Mexico",
            "abbreviation": "NM"
        },
        {
            "title": "New York",
            "abbreviation": "NY"
        },
        {
            "title": "North Carolina",
            "abbreviation": "NC"
        },
        {
            "title": "North Dakota",
            "abbreviation": "ND"
        },
        {
            "title": "Northern Mariana Islands",
            "abbreviation": "MP"
        },
        {
            "title": "Ohio",
            "abbreviation": "OH"
        },
        {
            "title": "Oklahoma",
            "abbreviation": "OK"
        },
        {
            "title": "Oregon",
            "abbreviation": "OR"
        },
        {
            "title": "Palau",
            "abbreviation": "PW"
        },
        {
            "title": "Pennsylvania",
            "abbreviation": "PA"
        },
        {
            "title": "Puerto Rico",
            "abbreviation": "PR"
        },
        {
            "title": "Rhode Island",
            "abbreviation": "RI"
        },
        {
            "title": "South Carolina",
            "abbreviation": "SC"
        },
        {
            "title": "South Dakota",
            "abbreviation": "SD"
        },
        {
            "title": "Tennessee",
            "abbreviation": "TN"
        },
        {
            "title": "Texas",
            "abbreviation": "TX"
        },
        {
            "title": "Utah",
            "abbreviation": "UT"
        },
        {
            "title": "Vermont",
            "abbreviation": "VT"
        },
        {
            "title": "Virgin Islands",
            "abbreviation": "VI"
        },
        {
            "title": "Virginia",
            "abbreviation": "VA"
        },
        {
            "title": "Washington",
            "abbreviation": "WA"
        },
        {
            "title": "West Virginia",
            "abbreviation": "WV"
        },
        {
            "title": "Wisconsin",
            "abbreviation": "WI"
        },
        {
            "title": "Wyoming",
            "abbreviation": "WY"
        }
    ]
})