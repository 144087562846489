import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import Input from '../Components/UI/Input';
import Button from '../Components/UI/Button';
import Title from '../Components/UI/Title';
import Error from '../Components/UI/Error';

import {useRecoilState} from 'recoil';
import {userAtom} from '../Recoil/atoms';
import {createUserStatus, signup, updateUser} from '../Recoil/api/user';
import {getNextCode, saveCode, updateInvitation, updateReferal} from "../Recoil/api/data";
import IconEye from "../Components/UI/Icons/IconEye";
import cioIdentify from "../CIO/cio";


/**
 * Create user accounts
 * @param phone
 * @param referal
 * @param invitedBy
 * @param userStatus
 * @returns {JSX.Element}
 * @constructor
 */

export default function Signup ({email, phone, status, hostId}){

    const [user, setUser] = useRecoilState(userAtom);
    const [saving, setSaving] = useState(false);

    const [checked, setChecked] = useState(true)

    let history = useHistory();
    let {token} = useParams();

    const [newUser, setNewUser] = useState({
        firstName : '',
        lastName : '',
        email : email,
        password : '',
        repeatPassword : '',
        provider : 'email',
        phoneNumber : phone,
        invitingUser : hostId
    });
    const [error, setError] = useState('');
    const [ptype, setptype] = useState(true);

    const updateForm = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const submit = async (e) => {
        setSaving(true);
        setError('');
        e.preventDefault();


        if(newUser.repeatPassword !== newUser.password || !newUser.password){
            setError("Please make sure the password matches");
            setSaving(false);
            return
        }

        if(!newUser.firstName){
            setError("Please add a First Name");
            setSaving(false);
            return
        }

        if(!newUser.lastName){
            setError("Please add a Last Name");
            setSaving(false);
            return
        }

        if(!checked){
            setError("Please make sure to accept terms of use and privacy policy");
            setSaving(false);
            return
        }

        try {

            /**
             * get code to assign next. Should be obtained on an external service
             * @type {any|undefined}
             */
            const next = await getNextCode();
            let nextplus = parseInt(next[0].code) + 1;
            const nu = {
                ...newUser,
                restaurantId : nextplus,
            };
            delete nu.repeatPassword;

            /**
             * Signup User
             * @type {AxiosResponse<any>|undefined}
             */
            const su = await signup(nu);
            console.log("USER CREATED", su);
            window.localStorage.setItem('jwt', su.data.jwt);

            /**
             * save code. Suggested external service
             * @type {{code: number, user}}
             */
            let co = {
                code : nextplus,
                user : su.data.user.id
            }
            await saveCode(co);

            /**
             * create user status
             * @type {AxiosResponse<any>|undefined}
             */
            const ust = await createUserStatus({user : su.data.user.id, approved : status });

            /**
             * Update User add status to user
             * @type {AxiosResponse<any>|undefined}
             */
            await updateUser(su.data.user.id, {status : ust.data.id})

            /**
             * Comple invitation.
             * Suggested external service
             * @type {AxiosResponse<any>|undefined}
             */
            const inv = await updateInvitation(token);
            console.log(inv);



            /**
             * Set user
             *
             */
            cioIdentify(su.data.user);
            setUser(su.data.user);
            setSaving(false);
            history.push('/profile/1');
        }
        catch (e){
            console.log('SETTING ERROR---', e.response);
            setError(e.response?.status == 400 && "Email already exists");
            setSaving(false);
        }

    }

    return(
        <div>

            {user.id && history.push('/profile/1')}

            <div className='max-w-500px mhor-auto center pver-3'>

                <Title title='WELCOME' />

                <p className='f-500 mb-half f-playfair mobile:phor'>
                    You’ve been selected to apply
                    for The Park Private
                    Membership!
                </p>
            </div>

            <div className='max-w-600px mhor-auto mobile:phor left'>

                <div className='mb-2 grid2 col-gap-2 mobile:block mobile:mb'>
                    <Input className='mobile:mb' label='First Name' name='firstName' onChange={updateForm} />
                    <Input label='Last Name' name='lastName' onChange={updateForm} />
                </div>

                <Input label='Email' className='mb-2 mobile:mb' value={newUser.email} name='email' onChange={updateForm} />

                <Input label='Create Password' type={ptype ? "password" : "text"} className='mb-2 mobile:mb' name='password' onChange={updateForm}>
                    <a className="absolute right-2 top-half" onClick={()=>setptype(!ptype)}>
                        <IconEye className={`h-2rem w-1halfrem ${ptype ? "op-30" : "op-100"}`} />
                    </a>
                </Input>
                <Input label='Confirm Password' type={ptype ? "password" : "text"} className='mb-2 mobile:mb' name='repeatPassword' onChange={updateForm} />

                <div className='flex align-center mb-2 mobile:mb'>

                    <input type='checkbox' checked={checked} onChange={()=>setChecked(!checked)} />
                    <p className='f-small ml-half'>
                        I am at least 21 years old and agree to this website's Terms of Use and Privacy Policy.
                    </p>

                </div>

                <div className='flex justify-center'>

                        {saving ?
                            <Button className='phor-4 grey500 b-grey500 transition-5'>
                                SAVING PROFILE...
                            </Button>
                        :
                            <Button className='phor-4 hover:white hover:t-gold transition-5' onClick={submit}>
                                SIGN UP
                            </Button>
                        }

                </div>

                {error &&
                <Error msg={error} className='center f-small t-red mt f-700'>
                    {error}
                </Error>
                }

            </div>

        </div>
    )
}
