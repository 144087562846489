import React, {useEffect, useState} from "react";
import Button from "../Components/UI/Button";
import Title from "../Components/UI/Title";
import QRCode from "react-qr-code";

import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {useHistory} from "react-router-dom";
import {updateUser} from "../Recoil/api/user";
import cioIdentify from "../CIO/cio";
import placeholder from "../Components/UI/Styles/img/placeholder.jpg"

export default function Home (){

    const [user, setUser] = useRecoilState(userAtom);
    let history = useHistory();
    const [pop, setPop] = useState(false);

    const u = {...user};

    const [loaded, setLoaded] = useState(false)

    const maskDate = (date)=>{
        if(date){
            let d = new Date(date);
            return (`${d.getMonth() + 1} / ${d.getDate()} / ${d.getFullYear()}`)
        }
    }

    useEffect(()=> {
        if(!loaded){
            setLoaded(true);
            save({confirmed : true});
        }
    }, [loaded]);

    const save = async (data) => {
        try{
            const res = await updateUser(user.id, data);
            setUser(res.data);
            cioIdentify(res.data);
        }
        catch (e){
            console.log(e);
        }
    }

    return(
        <div>
            {user?.id ? null : history.push('/login')}

            <div className="pt-2">

                <div className="block center">
                    {user?.status?.approved ?
                        <div className="max-w-400px grey200 br-2 mb mhor-auto overflow-hidden mobile:mhor mobile:mb-2">
                            <img src={user?.profilePhoto?.url} className="w-100 obj-cover obj-center aspect1-1"/>
                        </div>
                        :
                        <div className="max-w-400px b b-grey400 grey200 br-2 mb mhor-auto overflow-hidden mobile:mhor mobile:mb-2">
                            <img src={placeholder} className="w-100 obj-cover obj-center aspect1-1"/>
                        </div>
                    }



                    {pop &&
                        <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
                            <div className="absolute edges black90">

                            </div>
                            <div className="relative z-100 white max-w-400px w-100 p-2 br">
                                <div className="pb mb b-bottom b-grey300 flex">
                                    <h5 className="grows center">Bookmark instructions</h5>
                                </div>
                                <div className="align-center justify-center center">
                                    <p className="mb">
                                        Please remember your login and bookmark this page for future use. You will need to present your QR to the door host for entry
                                    </p>
                                    <p className="mb">
                                        To save this page using your iPhone on Safari, you can tap the share button on the bottom of the page and tap “add to home screen”.
                                    </p>
                                    <p className="mb">
                                        To save this page using your Android phone on Chrome, tap the menu icon (3 dots in upper right-hand corner) and tap “add to home screen”.
                                    </p>

                                    <Button onClick={()=>setPop(false)} fontSize="f-small" className="w-100 relative phor-2 hover:white hover:t-gold transition-5" height="h-3rem">
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }

                    {user?.id &&
                    <div className="max-w-600px mhor-auto center mobile:mhor pt">

                        {user?.status?.approved &&
                            <QRCode value={`https://thepark-cms.herokuapp.com/user/${user?.id}/checkin`} size={200}/>
                        }
                    </div>
                    }
                </div>

                {user?.status?.approved &&
                    <div className="block center mt-2">
                        <a onClick={() => setPop(true)} className="f-underline">Bookmark instructions</a>
                    </div>
                }



            </div>

            <div className="max-w-500px mhor-auto center pver-3 mobile:pver">

                <div className="flex justify-center mobile:pt">
                    <div className="mb-2 mobile:mb">

                    </div>
                </div>
                <Title title={`${user?.firstName} ${user?.lastName}`} />

                {user?.status?.approved &&
                    <div className="grid2 mver-2">
                        <div className="b-right b-grey300">
                            <h6 className="f-500 mb-half f-playfair f-uppercase mobile:f-1rem">
                                Member Since
                            </h6>
                            <p>
                                {maskDate(user?.createdAt)}
                            </p>
                        </div>


                        <div>
                            <h6 className="f-500 mb-half f-playfair f-uppercase mobile:f-1rem">
                                Member ID
                            </h6>
                            <p className="f-1rem f-uppercase ">
                                {user?.restaurantId}{user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
                            </p>
                        </div>

                    </div>
                }

                {user?.status?.approved &&
                    <Button type="Link" to="/profile/refer" fontSize="f-small"
                            className="relative phor-2 hover:white hover:t-gold transition-5 mb" height="h-2rem">
                        Refer a Friend
                    </Button>
                }

                <br/>

                <Button type="a" href="mailto:info@theparkmembership.com" color="black" fontSize="f-small" className="relative phor-2 hover:white hover:t-black transition-5" height="h-2rem">
                    Contact Us
                </Button>
                <br />
                <p className="mt">
                    <a href="https://park14.com" className="t-black ">www.park14.com</a>
                </p>


            </div>




        </div>
    )
}
