import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import Input from "../Components/UI/Input";
import Button from "../Components/UI/Button";
import Title from "../Components/UI/Title";

import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {resetPassword} from "../Recoil/api/user";
import Error from "../Components/UI/Error";
import IconEye from "../Components/UI/Icons/IconEye";


export default function ResetPassword (){

    let {code} = useParams();

    const [user, setUser] = useRecoilState(userAtom);
    const [ptype, setptype] = useState(true);
    const [pwReseted, setPwReseted] = useState(false)

    let history = useHistory();

    const [newUser, setNewUser] = useState({
        confirmPassword : "",
        password : ""
    });
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const submit = async (e) => {
        setError("");
        e.preventDefault();

        if(newUser.password !== newUser.confirmPassword){
            setError("Passwords do not match");
            return
        }

        try {
            const su = await resetPassword(
                {
                    password : newUser.password,
                    code : code,
                    passwordConfirmation : newUser.confirmPassword
                }
            );
            console.log(su)
            setPwReseted(true);
        }
        catch (e){
            console.log("SETTING ERROR---", e.response);
        }

    }


    return(
        <div>

            {user.id && history.push('/profile/1')}

            <div className="max-w-500px mhor-auto center pver-3">

                <Title title="Reset Password" />

                <p className="f-500 mb-half f-playfair mobile:phor">
                    Please add a new password below
                </p>
                <p className="f-1rem mobile:phor">
                    You will be requested to login again with your new password
                </p>
            </div>

            {pwReseted ?
                <div className="center max-w-600px mhor-auto">
                    <h5 className="mobile:phor mb-2">
                        New Password Saved
                    </h5>
                    <Button type="Link" to="/" className="phor-4 hover:white hover:t-gold transition-5">
                        Go to Login
                    </Button>
                </div>

            :
                <div className="max-w-600px mhor-auto mobile:phor">

                    <Input className="mb" label="Password" type={ptype ? "password" : "text"} name="password" onChange={updateUser}>
                        <a className="absolute right-2 top-half" onClick={()=>setptype(!ptype)}>
                            <IconEye className={`h-2rem w-1halfrem ${ptype ? "op-30" : "op-100"}`} />
                        </a>
                    </Input>
                    <Input className="mb" label="Confirm Password" type={ptype ? "password" : "text"} name="confirmPassword" onChange={updateUser} />


                        <div className="flex justify-center">
                            <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={submit}>
                                Save new password
                            </Button>
                        </div>


                        {error &&
                            <Error msg={error} className="t-red center mt" />
                        }

                </div>

            }





        </div>
    )
}
