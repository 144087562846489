import React, {useState} from "react";
import Title from "../UI/Title";
import {useRecoilValueLoadable} from "recoil";
import {covers} from "../../Recoil/selectors";
import Header from "../Header";

export default function StepHeader({step}){

    const coversValue = useRecoilValueLoadable(covers);

    switch (coversValue.state) {
        case 'hasValue':

            const covrs = [...coversValue.contents];
            let st = step;
            if(st < 0){
                st = 6
            }
            const covr = covrs.filter(f => f.step == st);

            return (
                <div className="black mb-2 relative pb-2 mobile:pb">
                    <Header light={true}/>

                    <img src={covr[0].cover[0]?.url} title={`The Park ${covr[0].title}`} className="obj-cover w-100 h-100 op-40 absolute edges" />

                    <div className="center max-w-700px mhor-auto mt-3 t-white mobile:mt">
                        <Title title={covr[0].title} />

                        {step > 0 &&
                        <p className="h5 mobile:h6 f-500 mb-half f-playfair t-white relative">
                            {step}/6
                        </p>
                        }
                    </div>

                </div>

            )
        case 'loading':
            return <div>Loading...</div>
        case 'hasError':
            return (
                <div>Error</div>
            )
    }


}
