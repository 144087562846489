import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";

import Input from "../Components/UI/Input";
import Button from "../Components/UI/Button";
import Title from "../Components/UI/Title";

import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import {login} from "../Recoil/api/user";
import Error from "../Components/UI/Error";
import IconEye from "../Components/UI/Icons/IconEye";


export default function Login (){

    const [user, setUser] = useRecoilState(userAtom);
    const [ptype, setptype] = useState(true);

    let history = useHistory();

    const [newUser, setNewUser] = useState({
        identifier : "",
        password : ""
    });
    const [error, setError] = useState("");

    const updateUser = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setNewUser({...newUser, [name] : val});
    }

    const submit = async (e) => {
        setError("");
        e.preventDefault();

        try {
            const su = await login(newUser);
            setUser(su.data.user);
            window.localStorage.setItem('jwt', su.data.jwt);
            history.push("/profile/1");
        }
        catch (e){
            console.log("SETTING ERROR---", e.response?.data?.message[0]?.messages[0]?.message);
            setError(e.response?.data?.message[0]?.messages[0]?.message);
        }

    }


    return(
        <div>

            {user.id && history.push('/profile/1')}

            <div className="max-w-500px mhor-auto center pver-3">

                <Title title="Login" />

                <p className="f-500 mb-half f-playfair mobile:phor">
                    IF YOU ALREADY HAVE AN ACCOUNT LOGIN HERE
                </p>
                <p className="f-1rem mobile:phor">
                    Earn rewards, access private events, discounts and more
                </p>
            </div>

            <div className="max-w-600px mhor-auto mobile:phor">


                <Input label="Email" className="mb-2" name="identifier" onChange={updateUser} />

                <Input label="Password" type={ptype ? "password" : "text"} name="password" onChange={updateUser}>
                    <a className="absolute right-2 top-half" onClick={()=>setptype(!ptype)}>
                        <IconEye className={`h-2rem w-1halfrem ${ptype ? "op-30" : "op-100"}`} />
                    </a>
                </Input>
                <div className="mb-2 right mt-quarter">
                    <Link to="forgot-password" className="t-gold">Forgot Password? </Link>
                </div>


                <div className="flex justify-center">
                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={submit}>
                        Login
                    </Button>
                </div>


                {error && <Error msg={error} className="t-red center mt" /> }

            </div>



        </div>
    )
}
