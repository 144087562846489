import React, {useState} from "react";
import Title from "../../Components/UI/Title";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {statesAtom, userAtom} from "../../Recoil/atoms";
import Select from "../UI/Select";
import StepHeader from "./StepHeader";

export default function Step3({save}){

    console.log("Step 3")

    let history = useHistory();

    const user = useRecoilValue(userAtom);
    const states = useRecoilValue(statesAtom);
    let u = {...user};

    const [profile, setProfile] = useState({
        companyName : u.companyName ? u.companyName : '',
        companyWebsite : u.companyWebsite ? u.companyWebsite : '',
        companyCity : u.companyCity ? u.companyCity : '',
        companyState : u.companyState ? u.companyState : '',
        companyZipCode : u.companyZipCode ? u.companyZipCode : '',
        industry : u.industry ? u.industry : '',
        jobTitle : u.jobTitle ? u.jobTitle : ''
    });

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setProfile({...profile, [name] : val});
    }

    const saveProfile = async () => {
        console.log("Saving Profile")
        try {
            const res = await save(profile);
            history.push("/profile/4")
        }
        catch (e){
            console.log(e);
        }

    }

    return(
        <div>
            <StepHeader step={3} />
            <div className="max-w-600px mhor-auto mobile:phor">

                <Input label="Company Name" className="mb-2 mobile:mb" name="companyName" value={profile.companyName} onChange={changeText} />

                <div className="mb-2 col-gap-2 mobile:col-gap">
                    <Input label="Industry" name="industry" value={profile.industry} onChange={changeText} />
                </div>

                <div className="flex justify-center">

                    <Button type="Link" to="/profile/2" className="phor-2 hover:op-40 transition-5 b-black mr" color="white" fontColor="t-black">
                        Previous
                    </Button>

                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveProfile}>
                        Next
                    </Button>
                </div>

            </div>
        </div>
    )
}
