import React from "react";

export default function Error({msg, className, ...props}){

    return(
        <div className={className ? className : "pver t-red center"}>
            {msg}
        </div>
    )
}
