import React, {useEffect, useState} from "react";
import Input from "../UI/Input";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";

export default function BirthDate({format, className}){

    const user = useRecoilValue(userAtom);
    let u = {...user};

    const initialDate = ()=>{
        if (!u.birthday){
                return ""
        }
        let d = u.birthday.split('-');
        return (`${d[1]}/${d[2]}/${d[0]}`)
    }

    const [dateString, setDateString] = useState(initialDate());

    const maskDate = (e) => {

        let val = e.target.value;

        let v = val.replace(/\D/g,'').slice(0, 10);;
        if (v.length >= 5) {
            setDateString(`${v.slice(0,2)}/${v.slice(2,4)}/${v.slice(4)}`);
        }
        else if (v.length >= 3) {
            setDateString(`${v.slice(0,2)}/${v.slice(2)}`);
        }
        else{
            setDateString(v);
        }
    }

    useEffect(()=>{
        format(dateString);
    },[dateString]);

    return(
        <Input label="Birthdate" placeholder="MM/DD/YYYY" value={dateString} onChange={maskDate} maxLength="10" pattern="[0-9]{10}" className={className} />
    )
}
