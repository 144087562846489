import React, {useState} from "react";
import {Link} from "react-router-dom";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import ReferredBy from "../Form/ReferredBy";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {createInvitation} from "../../Recoil/api/data";
import StepHeader from "./StepHeader";
import {useHistory} from "react-router-dom";
import Error from "../UI/Error";

export default function Step6({save, noStep}){

    let history = useHistory();

    const [pop, setPop] = useState(false);
    const [recommend, setRecommend] =  useState(noStep ? noStep : false);
    const user = useRecoilValue(userAtom);
    let u = {...user};
    const [error, setError] = useState("");

    const [refer, setRefer] = useState(
        {
            "firstName": "",
            "lastName": "",
            "email": "",
            "phone": "",
            "industry": "",
            "instagram": "",
            "linkedin": "",
            "type" : "6206937156667a0016e79473",
            "guest": u.id,
        }
    );

    const logout = () => {
        window.localStorage.clear();
        window.location="/"
    }

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setRefer({...refer, [name] : val});
    }

    const saveReferral = async () => {
        if(recommend){

            if(!refer.firstName){
               setError("Please add First Name");
               return
            }

            if(!refer.lastName){
                setError("Please add Last Name");
                return
            }

            if(!refer.email){
                setError("Please add Email");
                return
            }

            if(refer.instagram || refer.linkedin){

            }
            else{
                setError("Please add linkedin or instagram for your referral.");
                return
            }

            if(!refer.phone || refer.phone.length !== 10){
                setError("Please add a valid phone Number");
                return
            }

            try{
                const res = await createInvitation(refer);
                if(noStep){
                    history.push('/');
                }
                else{
                    setPop(true);
                }

            }
            catch (e){
                throw e
            }
        }
        else {
            setPop(true);
        }


    }

    const endProfile = async () => {

        try {
            const res = await save({profileCompleted : true});
            history.push("/")
        }
        catch (e){
            console.log(e);
        }

    }


    return(
        <div>
            <StepHeader step={noStep ? -1 : 6} />
            <div className="max-w-600px mhor-auto mobile:phor">

                {!noStep ?
                    < ReferredBy />
                        :
                    <Link to="/" className="f-700 t-black f-small block mb">
                        {`<`} Back to Home
                    </Link>
                }

                <div className="p b-grey300 b-top"></div>

                <div className="flex mb-2 align-center">
                    <div className="grows mr">
                        <h4 className="h6">Would you like to recommend a member?</h4>
                    </div>
                    {!noStep &&
                        <Button className="phor-2 hover:white hover:t-gold transition-5 no-wrap" height="h-2rem"
                                onClick={() => {
                                    setRecommend(!recommend)
                                }}>
                            Recommend {recommend ? "-" : "+"}
                        </Button>
                    }
                </div>

                {recommend &&
                    <div>
                        <div className="mb-2 grid2 col-gap-2 mobile:block mobile:mb">
                            <Input label="First Name" className="mobile:mb" name="firstName" value={refer.firstName} onChange={changeText} />
                            <Input label="Last Name" name="lastName" value={refer.lastName} onChange={changeText} />
                        </div>

                        <Input label="Email" name="email" value={refer.email} className="mb-2" onChange={changeText} />
                        <Input label="Phone Number"className="mb-2" name="phone" value={refer.phone} onChange={changeText} />

                        <div className="mb-2  mobile:mb">
                            <Input label="Industry" name="industry" value={refer.industry} onChange={changeText} />
                        </div>
                        <div className="mb-2 grid2 col-gap-2 mobile:mb mobile:block">
                            <Input label="Instagram" className="mobile:mb" name="instagram" value={refer.instagram} onChange={changeText} />
                            <Input label="Linkedin" name="linkedin" value={refer.linkedin} onChange={changeText} />
                        </div>

                    </div>
                }

                <div className="p b-grey300 b-top"></div>

                {error &&
                    <Error msg={error} className="t-red block center mb" />
                }

                <div className="flex justify-center">

                    {noStep ?
                        <>
                            <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveReferral}>
                                Save Referral
                            </Button>
                        </>
                        :
                        <>
                            <Button type="Link" to="/profile/5" className="phor-2 hover:op-40 transition-5 b-black mr" color="white" fontColor="t-black">
                                Previous
                            </Button>

                            <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveReferral}>
                                Finish
                            </Button>
                        </>
                    }
                </div>


                {pop &&
                    <div className="fixed edges z-1000 w-100 h-100 flex align-center justify-center">
                        <div className="absolute edges black90">

                        </div>
                        <div className="relative z-100 white max-w-400px w-100 p-2 br">
                            <div className="pb mb b-bottom b-grey300 flex">
                                {u.status.approved ?
                                    <h5 className="grows center">
                                        Thank you for completing your
                                        profile information.
                                    </h5>
                                    :
                                    <h5 className="grows center">
                                        Your Membership Application
                                        is Pending
                                    </h5>
                                }
                            </div>
                            <div className="align-center justify-center center">
                                {u.status.approved ?
                                    <>
                                        <p className=" lh-150 mb">
                                            We'd like to welcome you to
                                            The Park Private Membership.
                                            On the next screen you'll find
                                            your membership card with your
                                            personal QR code for entry to
                                            The Park events.
                                        </p>
                                        <Button onClick={endProfile} fontSize="f-small" className="w-100 relative phor-2 hover:white hover:t-gold transition-5" height="h-3rem">
                                            Finish
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <p className=" lh-150 mb">
                                            Thank you for completing your profile
                                            information.<br /><br />
                                            Your application is being reviewed. If
                                            approved, you will receive a confirmation text
                                            with a OR code that will be required for entry.
                                        </p>
                                        <Button onClick={endProfile} fontSize="f-small" className="w-100 relative phor-2 hover:white hover:t-gold transition-5" height="h-3rem">
                                            Finish
                                        </Button>
                                        <div className="f-small center mt-half">
                                            <span>membership inquiries e-mail</span><br />
                                            <a href="mailto : info@theparkmembership.com" target="_blank">info@theparkmembership.com</a>
                                        </div>
                                    </>
                                }



                            </div>
                        </div>
                    </div>
                }


            </div>
        </div>
    )
}
