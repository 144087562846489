import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes/index';
import {  RecoilRoot,  atom,  selector,  useRecoilState,  useRecoilValue,} from 'recoil';

ReactDOM.render(
    <RecoilRoot>
        <React.StrictMode>
            <Routes />
        </React.StrictMode>
    </RecoilRoot>,
    document.getElementById('root')
);

