import React, {useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, useHistory
} from "react-router-dom";

import '../Components/UI/Styles/index'
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import Login from "../Containers/Login";
import Signup from "../Containers/Signup";
import ForgotPassword from "../Containers/ForgotPassword";
import Profile from "../Containers/Profile";
import Home from "../Containers/Home";
import {getUser} from "../Recoil/api/user";
import {useRecoilState} from "recoil";
import {userAtom} from "../Recoil/atoms";
import ResetPassword from "../Containers/ResetPassword";
import Token from "../Components/Signup/Token";

export default function Routes() {

    const [session] = useState(window.localStorage.getItem('jwt'));
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useRecoilState(userAtom);

    useEffect(async()=>{

        if (session){
            try {
                const res = await getUser();
                setUser({...user, ...res.data});
                setLoading(false);
            }
            catch (e){
                setLoading(false);
            }
        }
        else {
            setLoading(false);
        }


    },[session] );



    return (
      <Router>
          <div className="grows direction-column flex">
              {loading ?
                <div className="flex grows justify-center align-center">
                    Loading
                </div>
              :
                <>
                    <div className="grows">

                        <Switch>
                            <Route exact path="/token/:token">
                                <Header />
                                <Token />
                            </Route>
                            <Route exact path="/reset-password/:code">
                                <Header />
                                <ResetPassword />
                            </Route>
                            <Route exact path="/signup">
                                <Header />
                                <Signup />
                            </Route>
                            <Route exact path="/login">
                                <Header />
                                <Login />
                            </Route>
                            <Route exact path="/forgot-password">
                                <Header />
                                <ForgotPassword />
                            </Route>
                            <Route exact path="/profile/:step" component={Profile} />
                            <Route exact path="/">
                                <Header />
                                <Home />
                            </Route>
                        </Switch>

                    </div>
                    <Footer />
                </>
              }

          </div>
      </Router>
    );
}
