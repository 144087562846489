import React, {useEffect, useState} from "react";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import {getReferrals} from "../../Recoil/api/data";

export default function ReferredBy(){

    const user = useRecoilValue(userAtom);
    const [referral, setReferral] = useState('');
    const [loading, setLoading] = useState(true);


    useEffect(async ()=>{

        if(loading){
            try {
               const res = await getReferrals(user.email);
               if(res.length > 0){
                   setReferral(`${res[0].referredBy.firstName}`);
               }
               setLoading(false);
            }
            catch (e){
                console.log(e);
            }
        }
    },[loading]);

    return(
        <>
        {loading ?
            <div>
                Loading
            </div>
        :
            <div>
                {referral && <h3 className="h5 mb-2">Referred By {referral}</h3>}

            </div>

        }
        </>
    )
}
