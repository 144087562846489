import React, {useState} from "react";
import Title from "../../Components/UI/Title";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {statesAtom, userAtom} from "../../Recoil/atoms";
import Select from "../UI/Select";
import StepHeader from "./StepHeader";
import Error from "../UI/Error";

export default function Step2({save}){

    let history = useHistory();

    const user = useRecoilValue(userAtom);
    const states = useRecoilValue(statesAtom);
    let u = {...user};

    const [error, setError] = useState("");

    const [profile, setProfile] = useState({
        address : u.address ? u.address : '',
        city : u.city ? u.city : '',
        state : u.state ? u.state : '',
        zipCode : u.zipCode ? u.zipCode : '',
        phoneNumber : u.phoneNumber ? u.phoneNumber : ''
    });

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setProfile({...profile, [name] : val});
    }

    const saveProfile = async () => {

        if(!profile.phoneNumber || profile.phoneNumber.length !== 10){
            setError("Please add a valid phone number")
            return
        }

        try {
            const res = await save(profile);
            history.push("/profile/3")
        }
        catch (e){
            console.log(e);
        }

    }

    return(
        <div>
            <StepHeader step={2} />
            <div className="max-w-600px mhor-auto mobile:phor">

                <Input label="Street Address" className="mb-2 mobile:mb" onChange={changeText} value={profile.address} name="address" />

                <div className="mb-2 grid3 col-gap-2 mobile:block">
                    <Input label="City" className="mobile:mb" onChange={changeText} value={profile.city} name="city" />
                    <Select options={states} className="mobile:mb" label="State" onChange={changeText}  value={profile.state} name="state" />
                    <Input label="Zip Code" className="mobile:mb" onChange={changeText}  value={profile.zipCode} name="zipCode" />
                </div>
                <Input label="Phone Number" className="mb-2" onChange={changeText}  value={profile.phoneNumber} name="phoneNumber" maxLength="10" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" />

                {error &&
                    <Error msg={error} className="t-red block center mb" />
                }

                <div className="flex justify-center">

                    <Button type="Link" to="/profile/1" className="phor-2 hover:op-40 transition-5 b-black mr" color="white" fontColor="t-black">
                        Previous
                    </Button>

                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveProfile}>
                        Next
                    </Button>
                </div>

            </div>
        </div>
    )
}
