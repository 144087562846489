import React,{useState} from 'react';
import {Link} from 'react-router-dom';

export default function Button({className,color,height,type,fontSize,fontColor,children,to,...props}){

	const [defaultClass]=useState('b b-gold center justify-center b-0 inline-flex phor align-center cursor-pointer f-700 f-uppercase');
	const [defaultColor]=useState('gold');
	const [defaultFontSize]=useState('f-small');
	const [defaultFontColor]=useState('t-white');
	const [defaultHeight]=useState('h-3rem')

	switch(type){
		case 'button':
			return (
				<button
					className={`${className} ${color ? color : defaultColor} ${fontSize ? fontSize : defaultFontSize} ${fontColor ? fontColor : defaultFontColor} ${height ? height : defaultHeight} ${defaultClass}`} {...props}>
					{children}
				</button>
			);
			break;
		case 'Link':
			return (
				<Link to={to}
					  className={`${className} ${color ? color : defaultColor} ${fontSize ? fontSize : defaultFontSize} ${fontColor ? fontColor : defaultFontColor} ${height ? height : defaultHeight} ${defaultClass}`} {...props}>
					{children}
				</Link>
			);
			break;
		default:
			return (
				<a className={`${className} ${color ? color : defaultColor} ${fontSize ? fontSize : defaultFontSize} ${fontColor ? fontColor : defaultFontColor} ${height ? height : defaultHeight} ${defaultClass}`} {...props}>
					{children}
				</a>
			);
			break;
	}

}
