import React, {useState} from "react";
import Button from "../../Components/UI/Button";
import Input from "../../Components/UI/Input";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import StepHeader from "./StepHeader";
import Error from "../UI/Error";

export default function Step5({save}){

    let history = useHistory();

    const user = useRecoilValue(userAtom);
    const [error, setError] = useState('');
    let u = {...user};

    const [profile, setProfile] = useState({
        instagram : u.instagram ? u.instagram : '',
        facebook : u.facebook ? u.facebook : '',
        twitter : u.twitter ? u.twitter : '',
        linkedin : u.linkedin ? u.linkedin : '',
        tiktok : u.tiktok ? u.tiktok : '',
        otherSocial : u.otherSocial ? u.otherSocial : ''
    });

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setProfile({...profile, [name] : val});
    }

    const saveProfile = async () => {

        console.log("saving profile")
        if(!profile.instagram && !profile.tiktok && !profile.twitter && !profile.twitter && !profile.linkedin && !profile.facebook && !profile.otherSocial){
            setError("Please add at least one social network to continue.");
            return
        }

        try {
            const res = await save(profile);
            history.push("/profile/6")
        }
        catch (e){
            console.log(e);
        }

    }

    return(
        <div>
            <StepHeader step={5} />
            <div className="max-w-600px mhor-auto mobile:phor">

                <div className="mb-2 mobile:block">
                    <div className="flex mb-2 grey300 p-half br mobile:block mobile:p">
                        <div className="f-700  w-50 f-small flex align-center phor  mobile:phor-0 mobile:mb-half">
                            https://www.instagram.com/
                        </div>
                        <Input placeholder="Enter Instagram Handle" className="mobile:mb grows f-small" name="instagram" value={profile.instagram} onChange={changeText} />
                    </div>

                    <div className="flex mb-2 grey300 p-half br mobile:block mobile:p">
                        <div className="f-700  w-50 f-small flex align-center phor  mobile:phor-0 mobile:mb-half">
                            https://www.facebook.com/
                        </div>
                        <Input placeholder="Enter Facebook Handle" className="grows mobile:mb" name="facebook" value={profile.facebook} onChange={changeText} />
                    </div>
                    <div className="flex mb-2 grey300 p-half br mobile:block mobile:p">
                        <div className="f-700  w-50 f-small flex align-center phor  mobile:phor-0 mobile:mb-half">
                            https://www.twitter.com/
                        </div>
                        <Input placeholder="Enter Twitter Handle" className="grows mobile:mb" name="twitter" value={profile.twitter} onChange={changeText} />
                    </div>
                    <div className="flex mb-2 grey300 p-half br mobile:block mobile:p">
                        <div className="f-700  w-50 f-small flex align-center phor mobile:phor-0 mobile:mb-half">
                            https://www.linkedin.com/
                        </div>
                        <Input placeholder="Enter Linkedin Handle" className="grows mobile:mb" name="linkedin" value={profile.linkedin} onChange={changeText} />
                    </div>
                </div>

                {error &&
                    <Error msg={error} />
                }

                <div className="flex justify-center">

                    <Button type="a" href="/profile/4" className="phor-2 hover:op-40 transition-5 b-black mr" color="white" fontColor="t-black">
                        Previous
                    </Button>

                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveProfile}>
                        Next
                    </Button>
                </div>




            </div>
        </div>
    )
}
