import React, {useEffect, useState} from "react";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import Textarea from "../../Components/UI/Textarea";
import {useHistory} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {userAtom} from "../../Recoil/atoms";
import Interests from "../Form/Interests";
import StepHeader from "./StepHeader";
import Spirits from "../Form/Spirits";

export default function Step4({save}){

    let history = useHistory();

    const user = useRecoilValue(userAtom);
    let u = {...user};

    const [profile, setProfile] = useState({
        otherHobbies : u.otherHobbies ? u.otherHobbies : '',
        favoriteDrink : u.favoriteDrink ? u.favoriteDrink : '',
        favoriteDishes : u.favoriteDishes ? u.favoriteDishes : '',
        foodAllergies : u.foodAllergies ? u.foodAllergies : ''
    });

    const [hobbies, setHobbies] = useState([]);
    const [spirits, setSpirits] = useState([]);

    const changeText = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setProfile({...profile, [name] : val});
    }

    const saveProfile = async () => {
        let prof = {...profile};
        prof.hobbies = [...hobbies];
        prof.favoriteSpirits = [...spirits];
        try {
            const res = await save(prof);
            history.push("/profile/5")
        }
        catch (e){
            console.log(e);
        }
    }

    useEffect(()=>{
        if (user.hobbies && user.hobbies.length > 0){
            setHobbies([...user.hobbies]);
        }

        if (user.favoriteSpirits && user.favoriteSpirits.length > 0){
            setSpirits([...user.favoriteSpirits]);
        }

    }, [user]);

    return(
        <div>
            <StepHeader step={4} />
            <div className="max-w-600px mhor-auto mobile:phor">

                <div className="grey900 gold t-white br sh-10-black30 p-2 mb-2">
                    <h5 className="mb-2">Interests</h5>
                    <Interests setHobbies={setHobbies} hobbies={hobbies} />
                </div>

                <Input label="Other Hobbies" className="mb-2" name="otherHobbies" value={profile.otherHobbies} onChange={changeText} />

                <div className="p b-grey300 b-top"></div>

                <div className="grey900 t-white br sh-10-black30 p-2 mb-2">
                    <h5 className="mb-2">Favorite Spirits</h5>
                    <Spirits setSpirits={setSpirits} spiritsA={spirits} />
                </div>

                <Input label="Favorite Drink " className="mb-2 mobile:mb" name="favoriteDrink" value={profile.favoriteDrink} onChange={changeText} />

                <div className="flex justify-center">

                    <Button type="Link" to="/profile/3" className="phor-2 hover:op-40 transition-5 b-black mr" color="white" fontColor="t-black">
                        Previous
                    </Button>

                    <Button className="phor-4 hover:white hover:t-gold transition-5" onClick={saveProfile}>
                        Next
                    </Button>
                </div>

            </div>
        </div>
    )
}
