import React, {useState, useEffect} from "react";
import Signup from "../../Containers/Signup";
import {useParams} from 'react-router-dom';
import {getInvitation, getReferalsPhone} from "../../Recoil/api/data";

export default function Token(){

    let {token} = useParams();

    const [loading, setLoading] = useState(true);
    const [invitation, setInvitation] = useState({});
    const [hostId, setHostId] = useState("");

    useEffect(async ()=>{
        if(loading){
            const res = await getInvitation(token);
            setLoading(false);
            setHostId(res.host ? res.host._id : "");
            console.log(res);
            setInvitation(res);
        }
    }, [loading]);


    if (loading){
        return <div className="center pver-2">Loading...</div>
    }

    return(
        <div>
            {invitation?.id ?
                <div>
                    {invitation.completed ?
                        <div className="center pver-2 max-w-500px mhor-auto mobile:phor">
                            <h5 className="mobile:f-1rem">Your invitation has been used or expired. <br />Contact <a className="t-gold" href="mailto:info@theparkmembership.com"> info@theparkmembership.com</a> to issue a new one</h5>
                        </div>
                        :
                        <div className="center pver-2">
                            <Signup email={invitation.email} phone={invitation.phone} status={invitation.type.approved} hostId={hostId} />
                        </div>
                    }
                </div>
                :
                <div id="signup">
                    <Signup  />
                </div>
            }
        </div>
    )
}
