import React from "react";

export default function Title({title}){
    return(
        <div className="flex justify-center align-center mb-2 relative mobile:phor mobile:mb">
            <div className="h-halfrem b-bottom-2 b-gold w-100">
            </div>
            <h1 className={`h3 mobile:h4 f-uppercase f-500 phor-3 mobile:phor-2 z-500 ${title.length < 13 ? 'no-wrap' :''}`}>
                {title}
            </h1>
            <div className="h-halfrem b-bottom-2 b-gold w-100">
            </div>
        </div>
    )
}
