import {selector} from "recoil";
import {getUser} from "./api/user";
import {getGenders, getHobbies, getMaritalStatuses, getCovers, getSpirits} from "./api/data";

export const me = selector(
    {
        key: 'me',
        get: async () => {
            const res = await getUser();
            return res;
            }
        }
    );

export const genders = selector(
    {
        key: 'Genders',
        get: async () => {
            const res = await getGenders();
            return res;
        }
    }
);

export const maritalStatuses = selector(
    {
        key: 'MaritalStatuses',
        get: async () => {
            const res = await getMaritalStatuses();
            return res;
        }
    }
);

export const interests = selector(
    {
        key: 'Interests',
        get: async () => {
            const res = await getHobbies();
            return res;
        }
    }
);

export const spirits = selector(
    {
        key: 'Spirits',
        get: async () => {
            const res = await getSpirits();
            return res;
        }
    }
);


export const covers = selector(
    {
        key: 'Covers',
        get: async () => {
            const res = await getCovers();
            return res;
        }
    }
);
